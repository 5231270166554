@import "@aws-amplify/ui-react/styles.css";

[data-amplify-authenticator][data-variation=default] {
  min-height: 100vh;
  min-width: 100vw;
  background: url("./images/login-bg.jpg") no-repeat center center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
